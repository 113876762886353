import NavigationMenuElement from './NavigationMenuElement';
import { NavigationItem } from './navigation-props';

type NavigationGlobalNavigationDesktopProps = {
  menuItems: NavigationItem[];
  activeIndex: number;
  onMenuClick: (index: number) => void;
  renderingId?: string | undefined;
};

const NavigationGlobalNavigationDesktop = (
  props: NavigationGlobalNavigationDesktopProps
): JSX.Element | null => {
  const { menuItems, activeIndex } = props;

  return (
    <nav className="sticky z-[9] hidden justify-center items-center flex-auto bg-neutral-dark font-avidwalsheim text-white lg:block">
      <ul role="menubar" className="flex justify-center items-center text-lg">
        {menuItems &&
          menuItems.map((menuItem, index) => (
            <li key={index} role="menuitem">
              <button
                onClick={(): void => {
                  props.onMenuClick(index);
                }}
              >
                <NavigationMenuElement
                  navigationItem={menuItem}
                  active={activeIndex === index}
                  onMenuClick={(): void => props.onMenuClick(index)}
                  onLinkClick={(): void => props.onMenuClick(-1)}
                  className={`px-4 hover:bg-neutral-medium py-2 bg-neutral-dark hover:cursor-pointer font-bold font-avidwalsheimbold ${
                    index === activeIndex ? 'bg-neutral-medium' : ''
                  }`}
                />
              </button>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default NavigationGlobalNavigationDesktop;
